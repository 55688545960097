import React, { useState } from "react";
import { IntlProvider, FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import "../assets/css/cadastro.css";
import LogoLogin from "../assets/images/logo-login1.png";

const Register = () => {
	const [firstName, setFirstName] = useState(""); // Nome
	const [lastName, setLastName] = useState(""); // Sobrenome
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [username, setUsername] = useState(""); // Nome de usuário
	const [prefLanguage, setPrefLanguage] = useState("pt"); // Idioma preferido
	const [CelPhone, setCelPhone] = useState(""); // Celular
	const [NumDocument, setNumDocument] = useState(""); // CPF
	const [DateBirth, setDateBirth] = useState(""); // Data de nascimento
	const [perfil, setPerfil] = useState("master"); // Perfil de acesso
	const [typeDocument, settypeDocument] = useState("CPF"); // Perfil de acesso
	const [ContryCelPhone, setContryCelPhone] = useState("BR"); // Perfil de acesso
	const [error, setError] = useState("");
	const navigate = useNavigate();
	
	const handleSubmit = async (e) => {
		e.preventDefault();
	  
		if (password !== confirmPassword) {
		  setError("As senhas não coincidem.");
		  return;
		}
	  
		const completeName = `${firstName} ${lastName}`;
		const userData = {
		  completeName,
		  username,
		  email,
		  password,
		  prefLanguage,
		  CelPhone,
		  NumDocument,
		  DateBirth,
		  perfil,
		  ContryCelPhone,
		  typeDocument
		};
	  
		try {
		  const response = await fetch("https://api.newronix.com.br/api/auth/register", {
			method: "POST",
			headers: {
			  "Content-Type": "application/json",
			},
			body: JSON.stringify(userData),
		  });
	  
		  const contentType = response.headers.get("content-type");
	  
		  if (contentType && contentType.includes("application/json")) {
			// Se a resposta for JSON
			const data = await response.json();
			if (data.success) {
			  alert("Cadastro realizado com sucesso!");
			  navigate("/");
			} else {
			  setError(data.message || "Erro ao realizar o cadastro. Tente novamente.");
			}
		  } else {
			// Se a resposta não for JSON, exibe como texto
			const textResponse = await response.text();
			setError(textResponse || "Erro ao realizar o cadastro.");
		  }
		} catch (error) {
		  console.error("Erro ao enviar os dados:", error);
		  setError("Erro ao realizar o cadastro. Tente novamente mais tarde.");
		}
	  };

	return (
		// import ptMessages from '../locales/pt.json';
		// import enMessages from '../locales/en.json';
		// import esMessages from '../locales/es.json';

		// const messages = {
		//     pt: ptMessages,
		//     en: enMessages,
		//     es: esMessages,
		//   };

		<div id="login-page">
			<div className="cadastro-form-div">
				<form onSubmit={handleSubmit}>
					<img src={LogoLogin} alt="" />
					<h2>Cadastrar novo usuário.</h2>
					<div className="row">
						<div className="col-12 col-md-6">
							<div>
								<label htmlFor="username">Nome de Usuario</label>
								<input
									type="text"
									id="username"
									name="username"
									value={username}
									onChange={(e) => setUsername(e.target.value)}
								/>
							</div>
						</div>
						<div className="col-12 col-md-6">
							<div>
								<label htmlFor="firstName">Primeiro Nome</label>
								<input
									required
									type="text"
									id="firstName"
									name="firstName"
									value={firstName}
									onChange={(e) => setFirstName(e.target.value)}
								/>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-12 col-md-6">
							<div>
								<label htmlFor="lastName">Sobrenome</label>
								<input
									required
									type="text"
									id="lastName"
									name="lastName"
									value={lastName}
									onChange={(e) => setLastName(e.target.value)}
								/>
							</div>
						</div>
						<div className="col-12 col-md-6">
							<div>
								<label htmlFor="celular">Celular</label>
								<input
									required
									type="text"
									id="celular"
									name="celular"
									value={CelPhone}
									onChange={(e) => setCelPhone(e.target.value)}
								/>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-12 col-md-6">
							<div>
								<label htmlFor="cpf">CPF</label>
								<input required
									type="text"
									id="cpf"
									name="cpf"
									value={NumDocument}
									onChange={(e) => setNumDocument(e.target.value)} />
							</div>
						</div>
						<div className="col-12 col-md-6">
							<div>
								<label htmlFor="data">Data de Nascimento</label>
								<input
									required
									type="text"
									id="dataNascimento"
									name="dataNascimento"
									value={DateBirth}
									onChange={(e) => setDateBirth(e.target.value)}
								/>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-12 ">
							<div>
								<label htmlFor="email">Email</label>
								<input
									required
									type="email"
									id="email"
									name="email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</div>
						</div>

						<div className="col-12 ">
							<div>
								<label htmlFor="password">Senha</label>
								<input
									required
									type="password"
									id="password"
									name="password"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
								/>
							</div>
						</div>

						<div className="col-12 ">
							<div>
								<label htmlFor="password">Confirma a Senha</label>
								<input
									required
									type="password"
									id="confirmPassword"
									name="confirmPassword"
									value={confirmPassword}
									onChange={(e) => setConfirmPassword(e.target.value)}
								/>
							</div>
						</div>

						<div className="col-12">
							<div>
								<label htmlFor="nome">Perfil de acesso</label>
								<select
									id="perfil"
									name="perfil"
									value={perfil}
									onChange={(e) => setPerfil(e.target.value)}
								>
									<option value="master">Master</option>
									<option value="admin">Admin</option>
									<option value="consultor">Consultor</option>
								</select>
							</div>
						</div>

						<div className="col-12">
							<div>
								<label htmlFor="nome">Idioma Preferido</label>
								<select
									required
									id="prefLanguage"
									name="prefLanguage"
									value={prefLanguage}
									onChange={(e) => setPrefLanguage(e.target.value)}
								>
									<option value="pt">Português</option>
									<option value="es">Espanhol</option>
									<option value="en">Inglês</option>
								</select>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-12 col-md-6">
							<a href="/" className="voltar" value="Entrar">
								Voltar ao login
							</a>
						</div>
						<div className="col-12 col-md-6">
							<input type="submit" className="entrar" value="Criar sua conta" />
						</div>

						<div className="col-12">
						{error && <p className="error">{error}</p>}

						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default Register;
