import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Dash from './pages/dash';
import Login from './pages/login';
import Register from './pages/register';

function PrivateRoute({ children }) {
  const token = localStorage.getItem('token');
  return token ? children : <Navigate to="/login" />;
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/cadastro" element={<Register />} />
        {/* <Route exact path="/" element={<Dash />} /> */}
        <Route path="/dash" element={<PrivateRoute><Dash /></PrivateRoute>} />
      </Routes>
    </Router>
  );
}

export default App;