import React, { useEffect, useState } from "react";
import { IntlProvider, FormattedMessage } from 'react-intl';
import ptMessages from '../locales/pt.json';
import enMessages from '../locales/en.json';
import esMessages from '../locales/es.json';

import home from "../assets/images/home.png";
import Ordem_Producao1 from "../assets/images/Ordem_Producao1.svg";
import logoLateralCompleto from "../assets/images/logo-lateral-completo.png";
import menuIcon from "../assets/images/menu-icon.png";
import Solicitacao1 from "../assets/images/Solicitacao1.svg";
import edit from "../assets/images/edit.png";
import grafico from "../assets/images/grafico.png";
import Configuracao1 from "../assets/images/Configuracao1.svg";
import Suporte1 from "../assets/images/Suporte1.svg";
import Ajuda1 from "../assets/images/Ajuda1.svg";
import logoHeader from "../assets/images/logo-header.png";
import menuHeaderIcon from "../assets/images/menu-header-icon.png";
import user from "../assets/images/user.png";
import Meu_Cadastro from "../assets/images/Meu_Cadastro.png";
import Alterar_Senha from "../assets/images/Alterar_Senha.png";
import Editar_Foto_Perfil from "../assets/images/Editar_Foto_Perfil.png";
import brazil from "../assets/images/brazil.png";
import mexico from "../assets/images/mexico.png";
import usa from "../assets/images/usa.png";
import Ajuda from "../assets/images/Ajuda.png";
import Sair from "../assets/images/Sair.png";
import arrow from "../assets/images/arrow.png";
// import logo from '../assets/images/logo-footer.png'
import logoFooter from "../assets/images/logo-footer.png";
import '../assets/css/style.css'

const messages = {
    pt: ptMessages,
    en: enMessages,
    es: esMessages,
  };

const Dash = () => {

	const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(true);

	const toggleMenu = () => {
		setIsSidebarCollapsed((prevState) => !prevState);
	};

	// Estado para controlar a visibilidade da tabela
	const [isTableVisible, setTableVisible] = useState(true);
	const [isTableActive, setTableActive] = useState(true);

	// Função que alterna a visibilidade da tabela e a classe 'active'
	const toggleTable = () => {
		setTableVisible(!isTableVisible);
		setTableActive(!isTableActive);
	};

	const [isUserMenuActive, setIsUserMenuActive] = useState(false);

	const toggleUserMenu = () => {
		setIsUserMenuActive((prevState) => !prevState);
	};

	const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);

	const toggleSubmenu = () => {
		setIsSubmenuOpen((prevState) => !prevState);
	};

    const [locale, setLocale] = useState('pt'); // Idioma inicial como português
    
    const switchLanguage = (lang) => {
        setLocale(lang); // Muda o idioma para o selecionado
      };

	useEffect(() => {
		// Aqui você pode adicionar os event listeners, se necessário:
		// Exemplo:
	}, []);
	return (
        <IntlProvider locale={locale} messages={messages[locale]}>
			<div
				id="sidebar"
				className={`sidebar ${isSidebarCollapsed ? "collapsed" : ""}`}
			>
				<div className="menu-item logo-div">
					<img width="100" src={logoLateralCompleto} alt="" />
				</div>
				{/* <div className="menu-item"> */}
				<div className="menu-item" onClick={toggleMenu}>
					<img width="20" src={menuIcon} alt="" />
					<p><FormattedMessage id="recolher_menu" /></p>
				</div>
				<div className="menu-item">
					<img width="20" src={Ordem_Producao1} alt="" />
					<p><FormattedMessage id="ordem_producao" /></p>
				</div>
				<div className="menu-item">
					<img width="20" src={Solicitacao1} alt="" />
					<p><FormattedMessage id="solicitacoes" /></p>
				</div>

				<div className="menu-item">
					<img width="20" src={edit} alt="" />
					<p><FormattedMessage id="relatorios" /></p>
				</div>
				<div className="menu-item">
					<img width="20" src={grafico} alt="" />
					<p><FormattedMessage id="dashboard" /></p>
				</div>

				{/* <div className="menu-item relatorio" > */}
				<div className="menu-item relatorio" onClick={toggleSubmenu}>
					<div>
						<img width="20" src={Configuracao1} alt="" />
						<p>
                        <FormattedMessage id="configuracao" />
							<span className={`arrow ${isSubmenuOpen ? "open" : ""}`}>
								&#9654;
							</span>
						</p>
					</div>
					<div
						className="submenu"
						id="submenu-relatorio"
						style={{
							display: isSubmenuOpen ? "block" : "none",
							maxHeight: isSubmenuOpen ? "1000px" : "0", // ou ajuste conforme necessário
							transition: "max-height 0.3s ease-out",
						}}
					>
						<ul>
							<li><FormattedMessage id="cliente" /></li>
							<li><FormattedMessage id="produto" /></li>
							<li><FormattedMessage id="usuario" /></li>
						</ul>
					</div>
				</div>

				<div className="menu-item">
					<img width="20" src={Suporte1} alt="" />
					<p><FormattedMessage id="suporte" /></p>
				</div>
				<div className="menu-item">
					<img width="20" src={Ajuda1} alt="" />
					<p><FormattedMessage id="ajuda" /></p>
				</div>
			</div>

			<div id="content">
				<div className="header">
					<img src={logoHeader} className="img-fluid" width="100" alt="Logo" />
					<div className="d-flex align-items-center position-relative">
						<p className="data m-0">15 nov 2024</p>
						{/* <img  src={menuHeaderIcon} width="20"  */}
						<img
							onClick={toggleUserMenu}
							src={menuHeaderIcon}
							width="20"
							className="menu-header-icon img-fluid"
							alt="Icone do menu"
						/>
						<img
							src={user}
							width="25"
							className="user-img  img-fluid"
							alt="Usuario"
						/>
						<div className="nome-usuario">
							<h3 className="m-0">Zamite Chaves</h3>
							<small className="d-block">Master</small>
						</div>

						<div className={`user-menu ${isUserMenuActive ? "active" : ""}`}>
							<ul>
								<li>
									<img src={Meu_Cadastro} alt="" /> <FormattedMessage id="meu_cadastro" />
								</li>
								<li>
									<img src={Alterar_Senha} alt="" /> <FormattedMessage id="alterar_senha" />
								</li>
								<li>
									<img src={Editar_Foto_Perfil} alt="" /> <FormattedMessage id="alterar_foto_perfil" />
								</li>
								<li className="idioma">
                                <FormattedMessage id="idioma" />
                                    <img src={brazil} alt="PT-br" onClick={() => switchLanguage('pt')} />
									<img src={mexico} alt="ES" onClick={() => switchLanguage('es')} />
									<img src={usa} alt="EN-us" onClick={() => switchLanguage('en')} />
								</li>
							</ul>
							<hr />
							<ul>
								<li>
									<img src={Ajuda} alt="" /> <FormattedMessage id="ajuda" />
								</li>
								<li>
									<a className="sair" href="index.html">
										<img src={Sair} alt="" /> <FormattedMessage id="sair" />
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<div className="content-body">
					<div className="subtitle-div">
						<img src={home} width="20" alt="Home" />
						{/* <p className="m-0">{t('production')}</p> */}
					</div>
					<div className="alerts">
						<div className="todos">
							<p>
                            <FormattedMessage id="todos" />
                            </p>
							<h2 className="number">10</h2>
						</div>

						<div className="inicio">
							<p>
                            <FormattedMessage id="aguardando_inicio" />
							</p>
							<h2 className="number">03</h2>
						</div>

						<div className="aprovacao">
							<p>
                            <FormattedMessage id="aguardando_aprovacao" />
							</p>
							<h2 className="number">15</h2>
						</div>

						<div className="participantes">
							<p>
                            <FormattedMessage id="aguardando_participantes" />
							</p>
							<h2 className="number">15</h2>
						</div>

						<div className="rejeitados">
							<p><FormattedMessage id="rejeitado" /></p>
							<h2 className="number">15</h2>
						</div>
					</div>

					<div className="timeline">
						<div className="d-flex mb-4">
							<h4 className="me-4"><FormattedMessage id="ordem" /></h4>
							<h4  className='status'><FormattedMessage id="status" /> <FormattedMessage id="iniciada" /></h4>
							<h4 className="ciclo-de-vida"><FormattedMessage id="ciclo_de_vida" /></h4>
						</div>
						<ol>
							<li className="active">
								<span><FormattedMessage id="iniciado" /></span>
							</li>
							<li>
								<span><FormattedMessage id="aguardando_aprovacao" /></span>
							</li>
							<li>
								<span><FormattedMessage id="separacao" /></span>
							</li>
							<li>
								<span><FormattedMessage id="manipulacao_iniciada" /></span>
							</li>
							<li>
								<span><FormattedMessage id="cobranca" /></span>
							</li>
							<li>
								<span><FormattedMessage id="qualidade" /></span>
							</li>
							<li>
								<span><FormattedMessage id="armazenamento" /> </span>
							</li>
							<li>
								<span><FormattedMessage id="finalizado" /></span>
							</li>
						</ol>
					</div>

					<div className="tabela active">
						<div className="d-flex align-items-center">
							<div>
								{/* <img  src={arrow}  width="15" alt=""/> */}
								<img src={arrow} width="15" onClick={toggleTable} alt="" />
								<h4 className="m-0"><FormattedMessage id="em_criacao" /></h4>
							</div>
							<div>
								<a href="" className="active">
                                <FormattedMessage id="nova_ordem" />
								</a>
								<a href=""><FormattedMessage id="editar" /></a>
								<a href=""><FormattedMessage id="remover" /></a>
							</div>
						</div>

						<div
							id="tableContent"
							className={`table-content ${isTableVisible ? "show" : ""}`}
						>
							<table>
								<tr>
									<td>ID</td>

									<td>N.Ordem</td>

									<td>Descrição</td>

									<td>Item 3</td>

									<td>Item 4</td>

									<td>Item 5</td>
								</tr>

								<tr>
									<td>ID</td>

									<td>N.Ordem</td>

									<td>Descrição</td>

									<td>Item 3</td>

									<td>Item 4</td>

									<td>Item 5</td>
								</tr>

								<tr>
									<td>ID</td>

									<td>N.Ordem</td>

									<td>Descrição</td>

									<td>Item 3</td>

									<td>Item 4</td>

									<td>Item 5</td>
								</tr>

								<tr>
									<td>ID</td>

									<td>N.Ordem</td>

									<td>Descrição</td>

									<td>Item 3</td>

									<td>Item 4</td>

									<td>Item 5</td>
								</tr>

								<tr>
									<td>ID</td>

									<td>N.Ordem</td>

									<td>Descrição</td>

									<td>Item 3</td>

									<td>Item 4</td>

									<td>Item 5</td>
								</tr>

								<tr>
									<td>ID</td>

									<td>N.Ordem</td>

									<td>Descrição</td>

									<td>Item 3</td>

									<td>Item 4</td>

									<td>Item 5</td>
								</tr>

								<tr>
									<td>ID</td>

									<td>N.Ordem</td>

									<td>Descrição</td>

									<td>Item 3</td>

									<td>Item 4</td>

									<td>Item 5</td>
								</tr>

								<tr>
									<td>ID</td>

									<td>N.Ordem</td>

									<td>Descrição</td>

									<td>Item 3</td>

									<td>Item 4</td>

									<td>Item 5</td>
								</tr>

								<tr>
									<td>ID</td>

									<td>N.Ordem</td>

									<td>Descrição</td>

									<td>Item 3</td>

									<td>Item 4</td>

									<td>Item 5</td>
								</tr>

								<tr>
									<td>ID</td>

									<td>N.Ordem</td>

									<td>Descrição</td>

									<td>Item 3</td>

									<td>Item 4</td>

									<td>Item 5</td>
								</tr>

								<tr>
									<td>ID</td>

									<td>N.Ordem</td>

									<td>Descrição</td>

									<td>Item 3</td>

									<td>Item 4</td>

									<td>Item 5</td>
								</tr>

								<tr>
									<td>ID</td>

									<td>N.Ordem</td>

									<td>Descrição</td>

									<td>Item 3</td>

									<td>Item 4</td>

									<td>Item 5</td>
								</tr>

								<tr>
									<td>ID</td>

									<td>N.Ordem</td>

									<td>Descrição</td>

									<td>Item 3</td>

									<td>Item 4</td>

									<td>Item 5</td>
								</tr>
								<tr>
									<td>ID</td>

									<td>N.Ordem</td>

									<td>Descrição</td>

									<td>Item 3</td>

									<td>Item 4</td>

									<td>Item 5</td>
								</tr>

								<tr>
									<td>ID</td>

									<td>N.Ordem</td>

									<td>Descrição</td>

									<td>Item 3</td>

									<td>Item 4</td>

									<td>Item 5</td>
								</tr>

								<tr>
									<td>ID</td>

									<td>N.Ordem</td>

									<td>Descrição</td>

									<td>Item 3</td>

									<td>Item 4</td>

									<td>Item 5</td>
								</tr>

								<tr>
									<td>ID</td>

									<td>N.Ordem</td>

									<td>Descrição</td>

									<td>Item 3</td>

									<td>Item 4</td>

									<td>Item 5</td>
								</tr>
							</table>
						</div>
					</div>

					<div className="footer">
						<img
							src={logoFooter}
							className="logo-footer"
							width="250"
							alt="Logo Roma"
						/>
					</div>
				</div>
			</div>
		</IntlProvider>
	);
};
export default Dash;
